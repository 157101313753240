
import React from "react"
import styled from "styled-components"

import Layout from "../layouts/layout"
import ImgBackground from "../components/imgBackground"

export default ({ data }) => (    
    <Layout>
        <FullPageDiv>
            <ImgBackground/>
            <h1>Thanks for the message</h1>
            <h1>We'll be in touch</h1>
        </FullPageDiv>
    </Layout>
)

const FullPageDiv = styled.div`
    height: 100vh;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`